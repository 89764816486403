import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/HomeView.vue'
import AppView from '../views/AppView.vue'
import PlataformaCreate from '../views/CadastrarPlataforma.vue'
import jwt_decode from "jwt-decode";

Vue.use(VueRouter)

const router = new VueRouter({

  routes: [{
      path: '/',
      name: 'Login',
      component: Login,
      meta: {
        guest: true,
      },
    },
    {
      path: '/app',
      name: 'app',
      component: AppView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/app/cadastrar-plataforma',
      name: 'plataforma',
      component: PlataformaCreate,
      meta: {
        requiresAuth: true,
      },
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = localStorage.getItem("token");
    let decoded = false;
    if (token == null) {
      decoded = false;
    } else {
      decoded = jwt_decode(token);
      decoded = decoded != null ? true : false;
    }
    if (!decoded) {
      next("/");
      return;
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router