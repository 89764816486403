import axios from "axios";

export const login = axios.create({
    baseURL: 'https://backend-prod1.leveduca.com.br:4343/api/'

});

export const plataforma = axios.create({
    baseURL: 'https://backend-prod1.leveduca.com.br:4343/api/'

});

export const consulta = axios.create({
    baseURL: 'http://127.0.0.1:8000/api/'
});