import {
    consulta
} from "./config";

import { getToken } from "./auth";

var md5 = require('locutus/php/strings/md5')

function dataAtualFormatada() {
    var data = new Date(),
        dia = data.getDate().toString().padStart(2, '0'),
        mes = (data.getMonth() + 1).toString().padStart(2, '0'),
        ano = data.getFullYear();
    return dia +  mes  + ano;
}

let cnpj = '31.405.199/0001-61';
let data = new Date();
let diaAtual = dataAtualFormatada(data)
let token = md5(cnpj + diaAtual);

export default {
    listar: (headers) => {
        return consulta.get("v1/api-suporte/consulta-api-mk/netzon/49092630802", headers);
    }
}