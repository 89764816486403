<template>
  <div id="app">
    <div id="main" v-bind:style="wallpapers[Math.floor(Math.random() * wallpapers.length)]">
    <div class="bg-overlay">
      <router-view />
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    wallpapers: [
      "background-image: url('images/bg-1.png')",
      "background-image: url('images/bg-2.png')"
      ]
    }
  },
  filters: {
    randomWall(min, max){
      return Math.random() * (max - min) + min;
    }
  }
}
</script>

<!-- ==== STYLE GLOBAL ==== -->
<style>
  @import url("./assets/css/style.css");

  #main{
    background-image: radial-gradient( circle farthest-corner at 7.2% 13.6%,  rgba(37,249,245,1) 0%, rgba(8,70,218,1) 90% );
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    height: 100%;
  }

  .bg-overlay{
    background: rgba(0, 0, 0,0.5);
    min-height: 100vh;
    height: 100%;
  }
</style>