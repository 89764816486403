<template>
  <div class="api-mk-ui-container">
    <div class="navbar-content">
      <a href="#">
        <img src="@/assets/logo-nubbi.png" alt="Logo Nubbi" width="150" />
      </a>
      
      <div class="timer">
        <Timer/>
      </div>

      <li class="nav-item dropdown" style="list-style: none;" v-if="token && user">
          <a class="nav-link dropdown-toggle user-name" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="@/assets/avatar.png" class="rounded-circle avatar" alt="Avatar" />
            {{user.replace('.', " ")}}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <!-- <li><router-link class="dropdown-item" to="/app/cadastrar-plataforma">Cadastrar plataforma</router-link></li> -->
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" @click="logoff()" href="#">Sair</a></li>
          </ul>
        </li>
    </div>
  </div>
</template>

<script>
import Timer from '@/components/Timer.vue'
  export default {
    components: {
      Timer
    },
    data() {
      return {
        token: null,
        user: null
      }
    },
    methods: {
      logoff() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.$router.push("/")
      }
    },
    created() {
      this.token = localStorage.getItem("token");
      this.user = localStorage.getItem("user");
    }
  }
</script>

<style scoped>
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--text);
  }

  a i {
    margin: 0 5px;
  }

  .user-name {
    text-transform: capitalize;
  }

  .avatar {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    object-fit: cover;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 992px) {
    .timer {
      display: none;
    }	
  }
</style>
