<template>
  <section>
    <!-- ==== AUTH FORM ==== -->
    <div class="container">

       <!-- ==== ALERTAS ==== --> 
        <div class="alerts-contents">
          <div class="alerts">
          <div v-if="erros">
            <div v-bind:class="erros.tipo" role="alert">
              <span><i v-bind:class="erros.icone"></i></span>
              {{ erros.mensagem }}
            </div>
          </div>
        </div>
        </div>

      <div class="auth">
        <div class="auth-content rounded">
          <div class="auth-header text-center">
            <h1 class="mt-3">Fazer Login</h1>
          </div>

          <!-- ==== FORMULÁRIO ==== -->
          <form @submit.prevent="login">
            <div class="form-group">
              <input class="form-control" type="text" placeholder="Seu email" v-model="loginDate.email" />
              <div class="icon">
                <i class="bx bx-envelope"></i>
              </div>
            </div>
            <div class="form-group">
              <input class="form-control" type="password" placeholder="Sua senha" v-model="loginDate.password" />
              <div class="icon">
                <i class="bx bxs-key"></i>
              </div>
            </div>

           <div class="d-flex justify-content-end p-0">
              <button class="btn btn-primary mt-3">
              Entrar
              <span v-if="loader == true" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            </button>
           </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<!-- ==== SCRIPTS ==== -->
<script>
  import { userIsLogged } from "./../services/auth";
  export default {
    data() {
      return {
        loginDate: {
          email: null,
          password: null
        },
        erros: {
          tipo: "",
          icone: "",
          mensagem: "",
        },
        loader: false
      }
    },
    mounted() {
      // Verifica se existe um usuário logado
      if (userIsLogged()) {
        this.$router.push("/app");
      }
    },
  };
</script>

<!-- ==== STYLE ==== -->
<style scoped>
  .auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .auth-header h2 {
    text-align: center;
  }

  .auth-content {
    width: 500px;
    padding: 40px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .auth-content button {
    width: 50%;
  }

  .form-group {
    margin: 20px 0;
    position: relative;
  }

  .form-group input {
    width: 100%;
    padding: 0px 52px;
  }

  .icon {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 4px 6px;
    height: 100%;
  }

  .icon i {
    color: #0b5ed7;
    padding: 2px 8px;
    font-size: 1.5em;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  .alerts-contents{
    position: absolute;
    top: 20px;
    right:  10px;
    width: 360px;
    z-index: 10;
  }
</style>