<template>
    <div class="text-center">
        <p v-if="hours <= 12">{{good_morning}}</p>
        <p v-if="hours > 12 && hours < 18">{{good_afternoon}}</p>
        <p v-if="hours > 17">{{good_night}}</p>
        <p>Hoje é {{day}} de {{month}} de {{year}} às {{hours}}:<span v-if="minutes < 10">0{{minutes}}</span>
        <span v-else>{{minutes}}</span></p>
    </div>
</template>

<script>
  var date = new Date();
  var months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];
  export default {
    data() {
      return {
        good_morning: "Bom dia!",
        good_afternoon: "Boa tarde!",
        good_night: "Boa noite!",
        day: date.getDate(),
        day_week: date.getDay(),
        month: months[date.getMonth()],
        year: date.getFullYear(),
        hours: date.getHours(),
        minutes: date.getMinutes()
      }
    },
  }
</script>