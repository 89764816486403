<template>
    <section>
        <!-- ==== HEADER ==== -->
        <header>
            <Header />
        </header>
        <!-- ==== MAIN ==== -->
        <main>
            <div class="api-mk-ui-container">
                <div class="api-mk-ui-content">

                    <div class="cadastrar-plataforma">
                        <div class="cadastrar-plataforma-content">
                            <div class="cadastrar-plataforma-header">
                                <h1>Cadastrar plataforma</h1>
                            </div>
                            <div class="cadastrar-plataforma-body">
                                <form @submit.prevent="cadastrarPlataforma" enctype="multipart/form-data">
                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Nome da plataforma"
                                            v-model="plataforma.nome" />
                                        <div class="icon">
                                            <i class="bx bx-server"></i>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group">
                                        <input class="form-control" type="text" placeholder="URL da plataforma"
                                            v-model="plataforma.url" />
                                        <div class="icon">
                                            <i class="bx bx-link"></i>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <input class="form-control" type="text" placeholder="Logo da plataforma"
                                            v-model="plataforma.logo" />
                                        <div class="icon">
                                            <i class="bx bx-image"></i>
                                        </div>
                                    </div> -->

                                    <!-- Upload de imagem -->
                                    <!-- <div class="form-group">
                                        <input class="form-control" type="file" v-model="plataforma.logo" @change="onFileChange" />
                                    </div> -->

                                    <b-form-file
                                        v-model="plataforma.logo"
                                        type="file"
                                        class="form-control"
                                        ref="file"
                                        browse-text="Buscar"
                                        accept=".jpg,.png,.svg"
                                        @change="onFileChange">
                                    </b-form-file>

                                    <div class="form-group">
                                        <input class="form-control" type="number" placeholder="Quantidade de usuários"
                                            v-model="plataforma.qtd_usuarios" />
                                        <div class="icon">
                                            <i class="bx bx-user"></i>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-end p-0">
                                        <button class="btn btn-primary mt-3">
                                            Cadastrar
                                            <span v-if="loader == true" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    </section>
</template>

<script>
    import Header from "@/components/Header.vue";
    import {
        getToken
    } from "./../services/auth";
    import axios from "axios";
    export default {
        components: {
            Header,
        },
        data() {
            return {
                plataforma: {
                    nome: null,
                    url: null,
                    logo: null,
                    qtd_usuarios: null,
                },
                loader: false
            };
        },
        methods: {
            cadastrarPlataforma() {
                const Swal = require('sweetalert2');
                // Veifica se as plataformas estão vazias
                this.loader = true;
                if (
                    this.plataforma.nome == null ||
                    this.plataforma.logo == null ||
                    this.plataforma.qtd_usuarios == null
                )
                 {
                    Swal.fire({
                        title: 'Oops...',
                        text: 'Preencha todos os campos!',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                    this.loader = false;
                    return false;
                }

                const formData = new FormData();
                formData.append("nome", this.plataforma.nome);
                formData.append("logo", this.plataforma.logo, this.plataforma.logo.name);
                formData.append("qtd_usuarios", this.plataforma.qtd_usuarios);
                const url = 'http://127.0.0.1:8000/api/v1/api-suporte/cadastrar-plataforma';

                axios.post(url, formData, 
                {
                    headers: {
                        'Authorization': `Bearer ${getToken()}`
                    }
                }).then((response) => {
                    this.loader = false;
                }).catch((error) => {
                    console.error(error);
                });
            },
            onFileChange(e) {
                console.log(e.target.files);
                const file = e.target.files[0];
                this.plataforma.logo = file;
                console.log(file);
            }
        },
    }
</script>

<!-- ==== STYLE ==== -->
<style>
    .cadastrar-plataforma {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cadastrar-plataforma-content {
        width: 100%;
        max-width: 500px;
        background: #fff;
        border-radius: 10px;
        padding: 30px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .cadastrar-plataforma-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    .cadastrar-plataforma-header h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
    }

    .cadastrar-plataforma-body {
        width: 100%;
    }

    .cadastrar-plataforma-body form {
        width: 100%;
    }

    .cadastrar-plataforma-body form .form-group {
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }

    .cadastrar-plataforma-body form .form-group .icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: #333;
    }

    .cadastrar-plataforma-body form .form-group .icon i {
        font-size: 1.2rem;
    }

    .cadastrar-plataforma-body form .form-group input {
        width: 100%;
        height: 50px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 1rem;
        color: #333;
        outline: none;
    }

    .cadastrar-plataforma-body form .form-group input::placeholder {
        color: #333;
    }

    .cadastrar-plataforma-body form .form-group input:focus {
        border: 1px solid #333;
    }

    .cadastrar-plataforma-body form .form-group input[type="password"] {
        letter-spacing: 5px;
    }
</style>