import Vue from 'vue'
import Login from "./../services/login";

Vue.mixin({
  methods: {
    login() {

      let email = this.loginDate.email;
      let password = this.loginDate.password;
      this.loader = true;

      // Verifica se os campos de login estão vazios
      switch (email && password) {
        case (''):
          this.loader = false;
          this.erros.tipo = "alert alert-info animate__animated animate__shakeX";
          this.erros.icone = "bx bx-info-circle";
          this.erros.mensagem = "Informe seus dados para fazer login!";
          return false;
      }

      // Chama a função de fazer login
      Login.login(email, password).then((resposta) => {
        // Adiciona o token e os dados do usuário no LocalStorage
        let user = email.split("@", 1);
        localStorage.setItem("token", resposta.data.access_token);
        localStorage.setItem("user", user);
        
        // Faz o login
        if (localStorage.getItem("token") != null) {
          this.loader = false;
          this.$router.push("/app");
        }
      }).catch((e) => {
        //Verifica se os dados informados para login estão corretos
        if (e.response.data.status == 1) {
          this.loader = false;
          this.erros.tipo = "alert alert-danger animate__animated animate__shakeY";
          this.erros.icone = "bx bx-error";
          this.erros.mensagem = "Email ou senha incorretos!";
        }
      });
    }
  }
});