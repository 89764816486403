<template>
    <section>
        <!-- ==== HEADER ==== -->
        <header>
            <Header />
        </header>
        <main>
            <div class="api-mk-ui-container">
                <div class="api-mk-ui-content">
                    <!-- ==== FORMULÁRIO DE CONSULTA ==== -->
                    <div class="article-form">
                        <form @submit.prevent="listar">
                            <div class="form-header text-center p-3 mb-3">
                                <h1 class="mt-3">Suporte Nubbi</h1>
                            </div>

                            <div class="col-12" v-if="plataformasApi == ''">
                            <Skeleton/>
                            </div>

                            <div class="col-12" v-else>
                                <select class="form-select" aria-label="Default select example" v-model="consulta.provedor">
                                    <option selected disabled>Escolha um provedor</option>
                                    <option v-for="plataforma in plataformasApi" v-bind:key="plataforma.id" :value="plataforma.slug">
                                        {{plataforma.titulo}}
                                    </option>
                                </select>
                            </div>

                            <div class="pretty p-switch p-fill mt-4">
                                <input type="checkbox" @click="verificaSenha(!senha)" />
                                <div class="state">
                                    <label>{{ modoPesquisa }}</label>
                                </div>
                            </div>

                            <div class="form-group mt-3">
                                <div class="form-input">
                                    <input class="form-control" type="text" placeholder="Documento do usuário"
                                        v-model="consulta.documento" />
                                </div>

                                <div class="form-input">
                                    <input class="form-control" type="text" :disabled="!senha ? true : false" placeholder="Senha do usuário"
                                        v-model="consulta.senha" />
                                </div> 
                            </div>

                            <div class="form-group mt-3 justify-content-end">
                                <div class="form-input mt-3">
                                    <button class="btn-block btn btn-primary mt-3">Consultar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- ==== EXIBIÇÃO DA CONSULTA ==== -->
                    <div class="article">
                        <div v-bind:class="card">
                            <div v-if="usuarios == ''">
                                <div v-if="erros">
                                    <div v-bind:class="erros.tipo" role="alert">
                                        <span><i v-bind:class="erros.icone"></i></span>
                                        {{ erros.mensagem }}

                                        <span class="ms-3" v-if="erros.mensagem == 'Consultando na API'">
                                            <div v-bind:class="loader" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="list-content" v-else>
                                <p class="alert alert-info p-2 rounded">
                                    <span><i class='bx bx-search'></i></span>
                                    Resultados da consulta por: &nbsp;
                                    <strong>{{ consulta.provedor | toUpperCase() }}</strong>&nbsp; e &nbsp;
                                    <strong>{{ consulta.documento }}</strong>
                                </p>

                                <p class="mt-3 text-center alert alert-success  p-2 rounded">
                                   <span><i class='bx bx-time-five'></i></span>
                                   Consulta realizada às {{ dataConsulta | formatarData() }}
                                </p>

                                <div class="d-flex">
                                    <b>Nome:</b>
                                    <p>{{ usuarios.nome }}</p>
                                </div>

                                <div class="d-flex">
                                    <b>Documento:</b>
                                    <p>{{ usuarios.documento }}</p>
                                </div>

                                <div class="d-flex">
                                    <b>Email:</b>
                                    <p>{{ usuarios.email }}</p>
                                </div>

                                <div class="d-flex">
                                    <b>Usuário:</b>
                                    <p>{{ usuarios.usuario }}</p>
                                </div>

                                <div class="d-flex">
                                    <b>Senha:</b>
                                    <p>{{ usuarios.senha }}</p>
                                </div>

                                <div class="d-flex">
                                    <b>Status Usuário:</b>
                                    <p v-if="usuarios.status == 'A'" class="bg-success text-white rounded fw-bold p-1 mb-0">
                                        Ativo
                                    </p>
                                    <p v-else class="bg-danger text-white rounded fw-bold p-1">
                                        Inativo
                                    </p>
                                </div>

                                <div class="d-flex">
                                    <b>Possui Acesso(s) Leveduca:</b>
                                    <p v-if="usuarios.pacote === null || usuarios.pacote.length == 0" class="bg-danger text-white rounded fw-bold p-1">
                                        Não
                                    </p>
                                    <div v-else>
                                        <p class="bg-success text-white rounded fw-bold p-1 m-1" v-for="pacote in usuarios.pacote" :key="pacote.id">
                                            {{ pacote.nome }}
                                        </p>
                                    </div>
                                </div>

                                <div class="d-flex">
                                    <b>Possui Acesso Paramount:</b>
                                    <p v-if="usuarios.aplicativos === null || usuarios.aplicativos.length == 0" class="bg-danger text-white rounded fw-bold p-1">
                                        Não
                                    </p>
                                    <p v-else class="bg-success text-white rounded fw-bold p-1 mb-0">
                                        Sim
                                    </p>
                                </div>

                                <div class="d-flex">
                                    <b>Possui Acesso Clube:</b>
                                    <p v-if="usuarios.clube === null || !usuarios.clube" class="bg-danger text-white rounded fw-bold p-1">
                                        Não
                                    </p>
                                    <p v-else class="bg-success text-white rounded fw-bold p-1 mb-0"> 
                                        Sim
                                    </p>
                                </div>

                                <div class="d-flex" v-for="beneficio in usuarios.beneficios" :key="beneficio.id">
                                    <b>Possui Acesso {{ beneficio.nome }}:</b>
                                    <p v-if="!beneficio.status" class="bg-danger text-white rounded fw-bold p-1">
                                        Não
                                    </p>
                                    <p v-else class="bg-success text-white rounded fw-bold p-1 mb-0"> 
                                        Sim
                                    </p>
                                </div>


                                <div class="d-block">
                                    <p><b>Plano(s) do Cliente:</b></p>
                                    <div v-for="plano in usuarios.plano_cliente" :key="plano.index">
                                        <p v-if="plano.status == 'A'" class="bg-success text-white rounded fw-bold p-2 mb-2" style="width: max-content;">{{ plano.identificacao }}</p>
                                        <p v-else class="bg-danger text-white rounded fw-bold p-1 mb-2">{{ plano.identificacao }}</p>
                                    </div>
                                </div>

                                <div class="d-block" >
                                    <p><b>Planos CLUBE atribuídos na nossa base:</b></p>
                                    <p v-for="plano in usuarios.planos_na_nossa_base.clube" :key="plano">
                                        {{ plano }}
                                    </p>
                                </div>
                                
                                <div class="d-block" >
                                    <p><b>Planos LEVEDUCA atribuídos na nossa base:</b></p>
                                    <p v-for="plano in usuarios.planos_na_nossa_base.leveduca" :key="plano">
                                        {{ plano }}
                                    </p>
                                </div>

                                <div class="d-block" >
                                    <p><b>Planos PARAMOUNT atribuídos na nossa base:</b></p>
                                    <p v-for="plano in usuarios.planos_na_nossa_base.paramount" :key="plano">
                                        {{ plano }}
                                    </p>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </section>
</template>

<!-- ==== SCRIPTS ==== -->
<script>
    import Header from "@/components/Header.vue";
    import Skeleton from "@/components/Skeleton.vue";
    import Plataforma from "./../services/plataforma";
    import { getToken } from "./../services/auth";
    import axios from "axios";
    export default {
        components: {
            Header,Skeleton
        },
        // Dados de retorno
        data() {
            return {
                senha: false,
                modoPesquisa: "Pesquisar sem senha",
                dataConsulta: "",
                consulta: {
                    provedor: "Escolha um provedor",
                    documento: "",
                    senha: "",
                },
                usuarios: [],
                planos: [],
                plataformasApiNubbi: [],
                plataformasApiLms: [],
                plataformasApi: [],
                card: "",
                erros: {
                    tipo: "alert alert-info animate__animated animate__bounce",
                    icone: "bx bx-info-circle",
                    mensagem: "Selecione uma plataforma e digite um documento para pesquisar!",
                },
                loader: "",
            };
        },
        created() {
            // Carrega as plataformas ao carregar a página 
            this.plataformaApiNubbi();
            this.plataformaApiLms();
        },
        methods: {
            // Carrega as plataformas Api Nubbi
            plataformaApiNubbi() {
                Plataforma.plataforma({
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }).then((resposta) => {
                    // Salva as plataformas
                    this.plataformasApiNubbi = resposta.data;
                    // Pegando apenas dados uteis Titulo e slug
                    this.plataformasApiNubbi = this.plataformasApiNubbi.map((item) => {
                        return {
                            titulo: item.nome,
                            slug: item.plataforma_nome,
                        };
                    });
                })
            },
            // Carrega as plataformas Api Lms
            plataformaApiLms() {
                const url = 'https://backend-prod1.leveduca.com.br:4346/api/page/login-provedor';
                
                axios.get(url).then((resposta) => {
                    // Salva as plataformas
                    this.plataformasApiLms = resposta.data;
                    // Pegando apenas dados uteis Titulo e slug
                    this.plataformasApiLms = this.plataformasApiLms.map((item) => {
                        return {
                            titulo: item.titulo,
                            slug: item.slug,
                        };
                    });
                })
            },
            // Unifica os 2 arrays de plataformas e remove duplicatas
            plataformaApi() {
                // Unifica os 2 arrays de plataformas
                this.plataformasApi = this.plataformasApiNubbi.concat(this.plataformasApiLms);
                // Remove duplicatas
                 this.plataformasApi = this.plataformasApi.filter((item, index, self) =>
                     index === self.findIndex((t) => (
                         t.titulo === item.titulo && t.slug === item.slug
                     ))
                 );
            },
        },
        watch: {
            plataformasApiNubbi(){
                if(this.plataformasApiNubbi.length > 0){
                    this.plataformaApi();
                }
            },
            plataformasApiLms(){
                if(this.plataformasApiLms.length > 0){
                    this.plataformaApi();
                }
            }
        },
    };
</script>