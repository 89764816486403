import Vue from 'vue'

Vue.mixin({
    // Retorna a primeira letra da palavra em maiúsculo | Exemplo: netzon - Netzon
    filters: {
        toUpperCase(str) {
            return str[0].toUpperCase() + str.substring(1);
        },

        // Formata data para o padrão brasileiro | Exemplo: 2020-01-01 - 01/01/2020
        formatarData() {
            var data = new Date(),
                hora = data.getHours(),
                minutos = data.getMinutes(),
                dia = data.getDate().toString().padStart(2, "0"),
                mes = (data.getMonth() + 1).toString().padStart(2, "0"),
                ano = data.getFullYear();

                if(minutos < 10){
                    minutos = "0" + minutos;
                } else{
                    minutos = minutos;
                }

            return hora + ":" + minutos + " - " + dia + "/" + mes + "/" + ano;
        },
    },
})