import Vue from 'vue'
import Consulta from "../services/consulta";
import {
  getToken
} from "../services/auth";
import axios from "axios";
Vue.mixin({
  // Método para pesquisar usuários na API
  methods: {
    listar() {

      // Limpa o array de usuários
      this.usuarios = "";
      this.card = "";

      // Animação de consulta na API
      if (this.usuarios == "") {
        this.erros.tipo = "alert alert-info animate__animated animate__headShake";
        this.erros.icone = "bx bx-info-circle";
        this.erros.mensagem = "Consultando na API";
        this.loader = "spinner-border text-primary";
      }

      // Verifica se os campos de consultas estão vazios
      switch (this.consulta.provedor.length && this.consulta.documento.length) {
        case (0):
          this.erros.tipo = "alert alert-warning animate__animated animate__shakeY";
          this.erros.icone = "bx bx-error";
          this.erros.mensagem = "Informe uma plataforma e um documento.";
          return false;
      }

      // Adicionando dados da pesquisa em um Array
      let params = [
        this.consulta.provedor,
        this.consulta.documento,
        this.consulta.senha,
      ];

      // Formata os dados da consulta | Exemplo: provedor/documento/senha | Fix CORS
      let paramsUrl;
      if (this.consulta.senha == '') {
        paramsUrl = params.slice(0, 2).join("/");
      } else {
        paramsUrl = params.join("/");
      }

      const options = {
        method: 'POST',
        url: 'https://backend-prod1.leveduca.com.br:4343/api/v1/api-suporte/consulta-api-mk/' + paramsUrl,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        }
      };
      
      axios.request(options).then((response) => {
        // Verifica se o usuário existe
        if (!response.data.nome){
          this.erros.tipo = "alert alert-danger animate__animated animate__shakeY";
          this.erros.icone = "bx bx-error";
          this.erros.mensagem = "Cliente não encontrado! Experimente pesquisar com senha também!";
          return false;
        }
        // Variável que armazena os dados da consulta  
        this.usuarios = response.data;

        this.card = "card";

      });
    },

    // Pesquisar com ou sem senha
    verificaSenha(senha) {
      this.senha = senha;

      // Muda o texto do checkbox e  limpa o input
      if (senha == true) {
        this.modoPesquisa = "Pesquisar com senha";
      } else {
        this.modoPesquisa = "Pesquisar sem senha";
        this.consulta.senha = "";
      }
    },
  }
});