import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import '@/helpers/Login';
import '@/helpers/Consulta';
import '@/helpers/Filters';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { getToken } from "./services/auth";
import { store } from "./store";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Sweet Alert
import '@/plugins/SweetAlert'

Vue.config.productionTip = false

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      localStorage.clear();
      router.push("/");
    } else {
      throw error;
    }
  }
)

Vue.mixin({
  methods:{
    getToken(){
      return getToken()
    }
  }
});

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')